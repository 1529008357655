import { usePostHog } from 'posthog-js/react'

type PosthogIdentifyProps = {
  children: React.ReactNode
  ui: string | undefined
  appName: string | undefined
}

function PosthogIdentify({ children, ui, appName }: PosthogIdentifyProps) {
  const posthog = usePostHog()
  posthog?.identify(ui, {
    organisation: appName,
  })

  return <>{children}</>
}

export default PosthogIdentify

import React from 'react'
import { PostHogProvider } from 'posthog-js/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { GraphQLClientProvider } from '@utils/GraphQLClientProvider'
import PosthogIdentify from '@utils/PosthogIdentify'

const posthogApiKey = document.querySelector('meta[name="posthog-api-key"]')?.getAttribute('content') ?? ''
const posthogOptions = {
  api_host: 'https://us.i.posthog.com',
}

function BaseLayout({ children }) {
  const queryClient = new QueryClient()

  const ui = children.props.initialPage.props.analytics.ui
  const appName = children.props.initialPage.props.analytics.appName

  return (
    <PostHogProvider apiKey={posthogApiKey} options={posthogOptions}>
      <PosthogIdentify ui={ui} appName={appName}>
        <GraphQLClientProvider>
          <QueryClientProvider client={queryClient}>
            <main className="tailwind tw-bg-white tw-font-sans">{children}</main>
          </QueryClientProvider>
        </GraphQLClientProvider>
      </PosthogIdentify>
    </PostHogProvider>
  )
}

export default BaseLayout
